import React, { Component } from "react"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"
import Helmet from "react-helmet"
import { Form, Field } from "react-final-form"
import { StaticQuery, graphql } from "gatsby"
import request from "superagent"
import styles from "./signup.module.scss"
import layoutStyles from "./../Layout/layout.module.scss"
import Scrollbar from "smooth-scrollbar"
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll"

import TextInput from "components/Form/TextInput"
import Select from "react-select"
import Dropdown from "react-dropdown"
import ButtonAnimated from "components/ButtonAnimated"
import Loading from "components/Loading"

import ChevronDown from "assets/icons/chevron-down.svg"

class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
      loading: false,
      response: null,
      error: true,
      selectedOption: null,
    }
  }

  submit = async values => {
    // console.log("submittt", values)
    this.setState({
      loading: true,
      response: null,
      error: false,
    })
    request
      .post("https://cms.keytoe.nl/wp-json/v1/meetup/signup")
      .send(values)
      .then(response => {
        this.setState({
          response: response.body,
          loading: false,
          error: false,
        })
        // console.log("response.body", response.body)
      })
      .catch(error => {
        // console.log("errrrr", error)
        this.setState({
          response: error.response.body,
          loading: false,
          error: true,
        })
      })
  }

  componentDidMount() {
    const transition = anime
      .timeline({
        easing: "easeOutCubic",
        duration: 800,
        autoplay: false,
      })
      .add({
        targets: this.refs.component.querySelector(
          `.${styles.header} > h2 > span`
        ),
        duration: 400,
        translateY: ["73px", "0px"],
        opacity: [0, 1],
        easing: "easeInOutCirc(1, .5)",
      })
      .add({
        targets: this.refs.component.querySelectorAll(`.${styles.line}`),
        duration: 600,
        width: ["0%", "100%"],
        // opacity: [0, 1],
        easing: "easeInOutCirc(1, .5)",
      })
      .add({
        targets: this.refs.component.querySelectorAll(`.${styles.linebottom}`),
        duration: 600,
        width: ["0%", "100%"],
        // opacity: [0, 1],
        easing: "easeInOutCirc(1, .5)",
      })
      .add(
        {
          targets: this.refs.component.querySelector(`.${styles.form}`),
          duration: 600,
          // width: ["0%", "100%"],
          translateY: ["-24px", "0px"],
          opacity: [0, 1],
          easing: "easeInOutCirc(1, .5)",
        },
        "-=800"
      )
    this.setState({
      transition: transition,
    })

    if (typeof window !== `undefined`) {
      if (window.location.href.includes("#signup")) {
        if (window.innerWidth >= 1024) {
          Scrollbar.use(OverscrollPlugin)
          let scrollbar = Scrollbar.init(document.body, {
            damping: 0.15,
            plugins: {
              overscroll: {},
            },
          })
          setTimeout(() => {
            scrollbar.scrollIntoView(this.refs.component, { offsetTop: 110 })
          }, 200)
        } else {
          this.refs.component.scrollIntoView(true)
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inViewport && nextProps.enterCount === 1) {
      this.state.transition.play()
    } else if (!nextProps.inViewport && nextProps.enterCount < 1) {
      // if (this.state.transition && !this.state.transition.reversed) {
      //   this.state.transition.play()
      // }
    }
  }
  handleChange = (selectedOption, input) => {
    //console.log('event', selectedOption)
    this.setState({ selectedOption }, () =>
      input.onChange(selectedOption.node.wordpress_id)
    )
  }

  render() {
    const { data } = this.props
    const { response, loading, error, selectedOption } = this.state

    const meetups = this.props.data.allWordpressMeetup.edges
    const customDropdown = {
      container: provided => ({
        ...provided,
        width: "calc(100% + 2vw)",
      }),
      control: state => ({
        cursor: "pointer",
        display: "flex",
        borderBottom: "3px solid black",
      }),
      valueContainer: provided => ({
        ...provided,
        padding: "0",
      }),
      placeholder: () => ({
        fontSize: "2.1875em",
        lineHeight: "1.37",
      }),
      singleValue: () => ({
        fontSize: "2.1875em",
        lineHeight: "1.37",
      }),
      indicatorSeparator: () => ({}),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        transition: "transform 0.4s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      }),
      input: () => ({
        ...provided,
        position: "absolute",
      }),
      menu: () => ({
        position: "absolute",
        zIndex: "10",
        backgroundColor: "white",
        width: "100%",
      }),
      option: () => ({
        fontSize: "1.5625em",
        lineHeight: "1.37",
        cursor: "pointer",
        paddingTop: "10px",
      }),
    }

    const SelectAdapter = ({ input, ...rest }) => {
      let restArray = []
      //console.log('input', input)
      rest.options.map((item, i) => {
        item.label = item.node.title
        item.value = item.node.slug
        restArray.push(item)
      })
      const chevron = ({}) => <ChevronDown />
      return (
        <Select
          {...input}
          {...rest.meta}
          {...rest.styles}
          styles={customDropdown}
          value={this.state.selectedOption}
          placeholder="Kies een meetup"
          options={restArray}
          onChange={e => this.handleChange(e, input)}
          components={{ DropdownIndicator: chevron }}
          isSearchable={false}
        />
      )
    }
    //TODO: Add yyyy-mm-dd date format to request BACKEND
    //TODO: Give back intro description without HTML tags
    return (
      <>
        <Helmet className={styles.dropdown}>
          {meetups.map((item, i) => {
            return (
              <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org/",
                  "@type": "Event",
                  "name": "${item.node.title}",
                  "description": "${item.node.intro}",
                  "startDate": "${item.node.date}",
                  "endDate": "${item.node.date}",
                  "location": {
                    "@type": "Place",
                    "name": "Keytoe",
                    "address": {
                      "streetAddress": "Dr. Kuyperkade 28",
                      "addressLocality": "Maassluis",
                      "addressRegion": "Zuid-Holland"
                    }
                  }
                }
              `}</script>
            )
          })}
        </Helmet>
        <section className={styles.signup} ref="component" id="signup">
          <div className={[styles.wrapper, "wrapper"].join(" ")}>
            <header className={styles.header}>
              <span>Aanmelden</span>
              <span className={styles.line}></span>
            </header>
          </div>
          <div
            className={[styles.wrapper, "wrapper"].join(" ")}
            className={styles.form}
          >
            <Form
              onSubmit={this.submit}
              meetupsA={meetups}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className={[styles.container].join(" ")}>
                    <div
                      className={[styles.wrapper, "wrapper--content"].join(" ")}
                    >
                      <div className={styles.inner}>
                        <h3
                          className={styles.title}
                          dangerouslySetInnerHTML={{ __html: "Welke meetup?:" }}
                        />
                        {/* the dropdown menu*/}
                        <div className={styles.content}>
                          <Field
                            name="meetup"
                            component={SelectAdapter}
                            type="select"
                            placeholder="Meetup"
                            options={meetups}
                            styles={styles.content}
                          ></Field>
                          {selectedOption && (
                            <div
                              className={styles.intro}
                              dangerouslySetInnerHTML={{
                                __html: selectedOption.node.intro,
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={[styles.container].join(" ")}>
                    <div
                      className={[styles.wrapper, "wrapper--content"].join(" ")}
                    >
                      <div className={styles.inner}>
                        <h3
                          className={styles.title}
                          dangerouslySetInnerHTML={{ __html: "Gegevens:" }}
                        />
                        <div className={styles.content}>
                          <Field
                            name="name"
                            component={TextInput}
                            type="text"
                            placeholder="Naam"
                          />
                          <Field
                            name="emailaddress"
                            component={TextInput}
                            type="email"
                            placeholder="E-mailadres"
                          />
                          <Field
                            name="company"
                            component={TextInput}
                            type="text"
                            placeholder="Organisatie/bedrijfs naam"
                          />
                          <Field
                            name="amount"
                            component={TextInput}
                            type="text"
                            placeholder="Met hoeveel mensen kom je?"
                          />
                          {response ? (
                            <span className={styles.response}>
                              {response.message}
                            </span>
                          ) : loading ? (
                            <Loading />
                          ) : null}
                          {loading === false && error === true ? (
                            <ButtonAnimated
                              disabled={loading}
                              to="#!"
                              text="Aanmelden!"
                              type="submit"
                              invert
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
          <div className={[styles.wrapper, "wrapper"].join(" ")}>
            <span className={styles.linebottom}></span>
          </div>
        </section>
      </>
    )
  }
}

const SignupTransition = handleViewport(Signup, {
  threshold: 0.3,
})
// export default SignupTransition

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressMeetup {
          edges {
            node {
              id
              title
              slug
              intro
              wordpress_id
              date
            }
          }
        }
      }
    `}
    render={data => <SignupTransition data={data} {...props} />}
  />
)
